








































import { Vue, Component } from "vue-property-decorator";
import { Input, Button } from "element-ui";
import { AdminRouter } from "@/utils/routePathContsant";
import commonstore from "@/store/modules/common";
import faqStore from "@/store/modules/faq";

@Component({
  components: {
    Input,
    Button
  }
})
export default class FAQClass extends Vue {
  eId: any = 0;
  get AdminRouter() {
    return AdminRouter;
  }
  get FAQ() {
    return faqStore.faq;
  }

  async created() {
    let that = this;
    const id = that.$route.query["id"];
    if (id) {
      this.eId = id;
      faqStore.getFAQById(id);
    }
  }
}
